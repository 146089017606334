import React, {useState} from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
    faIdCard,
    faFileAlt,
    faQuestionCircle,
    faEnvelope,
    faBars,
    faPeopleGroup
} from '@fortawesome/free-solid-svg-icons';
import UserMenu from './UserMenu';
import {Link} from "react-router-dom";
import NotificationBell from "./atom/NotificationBell";

function Header() {
    const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);

    const toggleMobileMenu = () => {
        setMobileMenuOpen(!isMobileMenuOpen);
    };
const isHomePath = window.location.pathname === '/home' ||
                   window.location.pathname === '/' ||
                   /^\/connect\/\d+$/.test(window.location.pathname);
    return (
        <header className="header border-b-2">
            <div className="container mx-auto flex flex-col md:flex-row items-center justify-between">
                <div className="flex justify-between items-center w-full md:w-auto">
                    <h1 className="text-4xl font-bold p-6">
                              <Link to="/">
                            <img src="/whooks.png" alt="Whooso" className="hidden md:block" />
                            <img src="/whooks-logo.png" alt="Whooso" className="block md:hidden h-12" />
                        </Link>
                    </h1>
                    <button className="block md:hidden p-3" onClick={toggleMobileMenu}>
                        <FontAwesomeIcon icon={faBars} className="text-gray-500 text-4xl"/>
                    </button>
                </div>
                <nav className="hidden md:flex md:space-x-4 mt-4 md:mt-0 w-full ">
                    <ul className="flex flex-col md:flex-row md:space-x-4 md:space-y-0 items-center justify-between w-full">
                        <div className="flex space-x-4">
                            <li>
                                <a href="/home"
                                   className={`hover:bg-pink-50 p-3 rounded flex items-center space-x-2 ${isHomePath ? "primary-color" : ""}`}>
                                    <div className="flex items-center space-x-2">
                                        <FontAwesomeIcon icon={faPeopleGroup}
                                                         className={`text-2xl text-gray-500 ${isHomePath ? "primary-color" : ""}`}/>
                                        <div className="py-2">
                                            <span className="font-semibold text-xs">Connections</span>
                                        </div>
                                    </div>
                                </a>
                            </li>
                            <li>
                                <a href="/message"
                                   className={"hover:bg-pink-50 p-3 rounded flex items-center space-x-2 " + (window.location.pathname.startsWith('/message') ? "primary-color" : "")}>
                                    <div className="flex items-center space-x-2">
                                        <FontAwesomeIcon icon={faEnvelope}
                                                         className={"text-2xl text-gray-500" + (window.location.pathname.startsWith('/message') ? "primary-color" : "")}/>
                                        <div>
                                            <span className="font-semibold text-xs">Message</span>
                                            <p className={"text-xs " + (window.location.pathname.startsWith('/message') ? "primary-color" : "text-gray-600 ")}>Tell
                                                us what you think</p>
                                        </div>
                                    </div>
                                </a>

                            </li>
                            <li>
                                <a href="/contactus"
                                   className={"hover:bg-pink-50 p-3 rounded flex items-center space-x-2 " + (window.location.pathname === '/contactus' ? "primary-color" : "")}>
                                    <div className="flex items-center space-x-2">
                                        <FontAwesomeIcon icon={faQuestionCircle}
                                                         className={"text-2xl text-gray-500" + (window.location.pathname === '/contactus' ? "primary-color" : "")}/>
                                        <div>
                                            <span className="font-semibold text-xs">Help</span>
                                            <p className={"text-xs " + (window.location.pathname === '/contactus' ? "primary-color" : "text-gray-600 ")}>Take
                                                a Tour</p>
                                        </div>
                                    </div>
                                </a>
                            </li>
                        </div>
                        <li className="flex justify-end items-center">
                            <div className="mr-2"> {/* Add margin between the two components */}
                                <UserMenu/>
                            </div>
                            <div className="mr-6"> {/* Add margin between the two components */}
                                <NotificationBell/>
                            </div>
                        </li>

                    </ul>
                </nav>
            </div>

            {isMobileMenuOpen && (
                <div className="md:hidden absolute top-0 left-0 w-full  bg-gray-800 opacity-95 mt-20 z-10">
                    <div className="flex flex-col h-full  ">
                        <ul className="space-y-4 text-lg text-gray-100  ">
                            <li className="border-b-2 border-gray-600">
                                <a href="/"
                                   className="hover:bg-gray-700 p-3 rounded flex items-center space-x-2 transition duration-300">
                                    <FontAwesomeIcon icon={faIdCard} className="text-green-400"/>
                                    <span>Card</span>
                                </a>
                            </li>
                            {/*<li className="border-b-2 border-gray-600">*/}
                            {/*  <a href="/sample" className="hover:bg-gray-700 p-3 rounded flex items-center space-x-2 transition duration-300">*/}
                            {/*    <FontAwesomeIcon icon={faFileAlt} className="text-green-400" />*/}
                            {/*    <span>Resume</span>*/}
                            {/*  </a>*/}
                            {/*</li>*/}
                            <li className="border-b-2 border-gray-600">
                                <a href="/about"
                                   className="hover:bg-gray-700 p-3 rounded flex items-center space-x-2 transition duration-300">
                                    <FontAwesomeIcon icon={faQuestionCircle} className="text-green-400"/>
                                    <span>Help</span>
                                </a>
                            </li>
                            <li className="border-b-2 border-gray-600">
                                <a href="/contactus"
                                   className="hover:bg-gray-700 p-3 rounded flex items-center space-x-2 transition duration-300">
                                    <FontAwesomeIcon icon={faEnvelope} className="text-green-400"/>
                                    <span>Contact</span>
                                </a>
                            </li>

                        </ul>
                        <button
                            className="mt-4 mb-8 p-3 bg-pink-500 text-white rounded self-center shadow-md hover:bg-pink-600 transition duration-300"
                            onClick={toggleMobileMenu}>
                            Close Menu
                        </button>
                    </div>
                </div>

            )}
        </header>
    );
}

export default Header;
