import React from 'react';
import {GoogleOAuthProvider, GoogleLogin} from '@react-oauth/google';
import {useNavigate} from 'react-router-dom';
import config from '../config';

const {backendUrl} = config;
const GoogleLoginComponent = () => {
    const navigate = useNavigate();
    const handleLoginSuccess = (credentialResponse) => {
        console.log(credentialResponse);
        // Send the token to the backend
        fetch(`${backendUrl}/api/account/auth/google`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({token: credentialResponse.credential, google_auth: true}),
        })
            .then((res) => res.json())
            .then((data) => {
                if (data.token) {
                    // Store the token in localStorage
                    localStorage.setItem('authToken', data.token);
                    localStorage.setItem('userEmail', data.email);
                    localStorage.setItem('name', data.name);
                    localStorage.setItem('userId', data.id);
                    console.log('Backend response:', data);
                    navigate('/');
                }
            });
    };

    const handleLoginFailure = (error) => {
        console.error(error);
    };

    return (
        <div className="flex justify-center items-center">
            <GoogleOAuthProvider clientId="1087457640583-pc610n2le9ep98t3id18m5f3v5ofdp15.apps.googleusercontent.com">
                <GoogleLogin
                    onSuccess={handleLoginSuccess}
                    onError={handleLoginFailure}
                />
            </GoogleOAuthProvider>
        </div>

    );
};

export default GoogleLoginComponent;
