import React, {useState} from 'react';
import {postData} from '../../api/crud';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEnvelope} from "@fortawesome/free-solid-svg-icons";

const MessageButton = ({receiver_id}) => {
    const [loading, setLoading] = useState(false);
    const [redirected, setRedirected] = useState(false);
    const [messageId, setMessageId] = useState(null);

    const handleClick = async () => {
        setLoading(true);
        try {
            const payload = {receiver_id}; // Adjust the payload here if needed
            // Call postData function
            const data = await postData('message/init', payload);
            if (data) {
                console.log('Data posted successfully:', data);
                // Set the redirected state to true
                setRedirected(true);
                setMessageId(data.id); // Store message ID
            } else {
                console.error('Failed to post data.');
                // Handle error condition if needed
            }
        } catch (error) {
            console.error('Error posting data:', error);
            // Handle error condition if needed
        } finally {
            setLoading(false);
        }
    };

    // Render redirection if redirected
    if (redirected && messageId) {
        window.location.href = `/message/${messageId}`;
        return null; // Return null to prevent rendering the button
    }

    return (
            <button className='w-full h-16 bg-orange-500  text-xl text-white' onClick={handleClick}>
                <FontAwesomeIcon icon={faEnvelope}
                                 className={"text-2xl text-gray-100 "}/> Message
            </button>

    );
};

export default MessageButton;
