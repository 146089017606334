// src/CallingCard.js
import React, { useEffect, useState } from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEnvelope, faMapMarkerAlt, faPhone} from "@fortawesome/free-solid-svg-icons";
import {Link} from "react-router-dom";

const CallingCard = (data) => {


  return (
    <div className="border-2 p-4 rounded min-w-56" >
           <p >{data.name}</p>
        <p >{data.title}</p>
    </div>


  );
};


export default CallingCard;
