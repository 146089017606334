import React, {useEffect, useState} from 'react';
import config from '../../config';
import Alert from '../atom/Alert';

const {backendUrl} = config;

const CardForm = () => {
    const [alert, setAlert] = useState({show: false, type: '', message: ''});
    const userId = localStorage.getItem('userId');
    const [cardData, setCardData] = useState({
        name: '',
        title: '',
        company: '',
        industry: '',
        website: '',
        address: '',
        phone: '',
        email: '',
        theme: 'KenCallingCard',
    });

    const handleChange = (e) => {
        const {name, value} = e.target;
        setCardData((prevData) => ({...prevData, [name]: value}));
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const authToken = localStorage.getItem('authToken');
                if (!authToken) throw new Error('No auth token found');

                const response = await fetch(`${backendUrl}/api/card`,
                    {headers: {Authorization: `Bearer ${authToken}`},});
                if (!response.ok) throw new Error('Failed to fetch data');

                const data = await response.json();
                setCardData(data);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, []);

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const authToken = localStorage.getItem('authToken');
            if (!authToken) throw new Error('No auth token found');
            console.log(cardData);
            const response = await fetch(`${backendUrl}/api/card`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${authToken}`,
                },
                body: JSON.stringify(cardData),
            });

            if (!response.ok) throw new Error('Failed to save data');

            setAlert({show: true, type: 'success', message: 'Submitted successfully!'});

        } catch (error) {
            setAlert({show: true, type: 'error', message: 'Failed to save card. Please try again later.'});
        }
    };

    return (
        <div className="relative p-8">
            <Alert
                type={alert.type}
                message={alert.message}
                show={alert.show}
                onClose={() => setAlert({...alert, show: false})}
            />
            <form onSubmit={handleSubmit}>
                <div className="border-b-2 pb-6 m-6">
                    <div>
                        <div className="text-2xl font-bold pb-6">Card</div>
                    </div>
                    <div className="pt-2 pb-2">
                        <label htmlFor="name" className="block text-sm font-medium text-gray-700">Name</label>
                        <input
                            type="text"
                            name="name"
                            id="name"
                            value={cardData.name}
                            onChange={handleChange}
                            className="mt-1 block w-full px-3 py-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                        />
                    </div>
                    <div className="pt-2 pb-2">
                        <label htmlFor="title" className="block text-sm font-medium text-gray-700">Title</label>
                        <input
                            type="text"
                            name="title"
                            id="title"
                            value={cardData.title}
                            onChange={handleChange}
                            className="mt-1 block w-full px-3 py-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                        />
                    </div>
                    <div className="pt-2 pb-2">
                        <label htmlFor="company" className="block text-sm font-medium text-gray-700">Company</label>
                        <input
                            type="text"
                            name="company"
                            id="company"
                            value={cardData.company}
                            onChange={handleChange}
                            className="mt-1 block w-full px-3 py-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                        />
                    </div>
                                 <div className="pt-2 pb-2">
                        <label htmlFor="industry" className="block text-sm font-medium text-gray-700">Industry</label>
                        <input
                            type="text"
                            name="industry"
                            id="industry"
                            value={cardData.industry}
                            onChange={handleChange}
                            className="mt-1 block w-full px-3 py-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                        />
                    </div>
                                 <div className="pt-2 pb-2">
                        <label htmlFor="website" className="block text-sm font-medium text-gray-700">Website</label>
                        <input
                            type="text"
                            name="website"
                            id="website"
                            value={cardData.website}
                            onChange={handleChange}
                            className="mt-1 block w-full px-3 py-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                        />
                    </div>
                    <div className="pt-2 pb-2">
                        <label htmlFor="address" className="block text-sm font-medium text-gray-700">Address</label>
                        <input
                            type="text"
                            name="address"
                            id="address"
                            value={cardData.address}
                            onChange={handleChange}
                            className="mt-1 block w-full px-3 py-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                        />
                    </div>
                    <div className="pt-2 pb-2">
                        <label htmlFor="phone" className="block text-sm font-medium text-gray-700">Phone</label>
                        <input
                            type="text"
                            name="phone"
                            id="phone"
                            value={cardData.phone}
                            onChange={handleChange}
                            className="mt-1 block w-full px-3 py-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                        />
                    </div>
                    <div className="pt-2 pb-2">
                        <label htmlFor="email" className="block text-sm font-medium text-gray-700">Email</label>
                        <input
                            type="email"
                            name="email"
                            id="email"
                            value={cardData.email}
                            onChange={handleChange}
                            className="mt-1 block w-full px-3 py-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                        />
                    </div>
                    <div className="pt-2 pb-2">
                        <label htmlFor="theme" className="block text-sm font-medium text-gray-700">Theme</label>
                        <input
                            type="text"
                            name="theme"
                            id="theme"
                            value={cardData.theme}
                            onChange={handleChange}
                            className="mt-1 block w-full px-3 py-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                        />
                    </div>
                </div>
                <div className="flex justify-end space-x-4 pb-6 m-6">
                    <button
                        type="button"
                        className="px-4 py-2 bg-gray-300 text-gray-700 rounded-md hover:bg-gray-400 focus:outline-none focus:ring-2 focus:ring-gray-500"
                    >
                        Cancel
                    </button>
                    <button
                        type="submit"
                        className="px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500"
                    >
                        Save
                    </button>
                </div>
            </form>
        </div>
    );
};

export default CardForm;
