import React, { useState } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhone, faEnvelope, faMapMarkerAlt, faGlobe } from "@fortawesome/free-solid-svg-icons";

// Define themes
const themes = {
    default: {
        primary: "#1F2937", // Dark Blue
        secondary: "#F97316", // Orange
        accent: "#E5E7EB", // Light Gray
        background: "#FFFFFF", // White
        textDark: "#1F2937", // Dark Blue
        textLight: "#FFFFFF", // White
    },
    theme1: {
        primary: "#0D3B66", // Dark Blue
        secondary: "#FAF0CA", // Light Yellow
        accent: "#F4D35E", // Yellow
        background: "#EE964B", // Orange
        textDark: "#0D3B66", // Dark Blue
        textLight: "#FFFFFF", // White
    },
    theme2: {
        primary: "#2E4057", // Dark Blue
        secondary: "#FFD700", // Gold
        accent: "#D3D3D3", // Light Gray
        background: "#F5F5F5", // Light Gray
        textDark: "#2E4057", // Dark Blue
        textLight: "#FFFFFF", // White
    },
    // Add more themes here...
};

const C0001 = (data) => {
    const [theme, setTheme] = useState(themes.default);

    const handleThemeChange = (event) => {
        setTheme(themes[event.target.value]);
    };

    return (
        <div className="relative w-full flex flex-col h-full">
            <div className="p-4 flex items-center justify-between h-40" style={{ backgroundColor: theme.background }}>
                <div className="text-center">
                    <div className="text-3xl font-bold" style={{ color: theme.secondary }}>{data.company}</div>
                    <div className="capitalize" style={{ color: theme.textDark }}>{data.industry}</div>
                </div>
                <select onChange={handleThemeChange} className="bg-white border border-gray-300 p-2 rounded">
                    <option value="default">Default</option>
                    <option value="theme1">Theme 1</option>
                    <option value="theme2">Theme 2</option>
                    {/* Add more themes here... */}
                </select>
            </div>
            <div className="flex-1">
                <div className="flex flex-col h-full" style={{ backgroundColor: theme.primary, color: theme.textLight }}>
                    <div className="h-4" style={{ backgroundColor: theme.secondary }}></div>
                    <div className="p-4 mt-6">
                        <div className="text-2xl font-bold capitalize">{data.name}</div>
                        <div className="text-sm mb-6" style={{ color: theme.accent }}>{data.title}</div>
                        <div className="flex items-center mb-2">
                            <FontAwesomeIcon icon={faPhone} className="text-2xl p-1" style={{ color: theme.secondary }} />
                            <div className="ml-2">{data.phone}</div>
                        </div>
                        <div className="flex items-center mb-2">
                            <FontAwesomeIcon icon={faEnvelope} className="text-2xl p-1" style={{ color: theme.secondary }} />
                            <div className="ml-2">{data.email}</div>
                        </div>
                        <div className="flex items-center mb-2">
                            <FontAwesomeIcon icon={faGlobe} className="text-2xl p-1" style={{ color: theme.secondary }} />
                            <div className="ml-2">{data.website}</div>
                        </div>
                        <div className="flex items-center">
                            <FontAwesomeIcon icon={faMapMarkerAlt} className="text-2xl p-1" style={{ color: theme.secondary }} />
                            <div className="ml-2">{data.address}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default C0001;
