import React, { useState, useEffect } from 'react';
import config from '../config';
const { backendUrl } = config;

function NameViewer() {
    const [account, setAccount] = useState({
        email: '',
        name: '',
        invitation_code: '',
        address: ''
    });

    const [isLoading, setIsLoading] = useState(true);

    // Function to fetch account details from API
    const fetchAccountDetails = async () => {
        try {
            const authToken = localStorage.getItem('authToken');
            if (!authToken) throw new Error('No auth token found');

            const response = await fetch(`${backendUrl}/api/account/39`, {
                headers: {
                    Authorization: `Bearer ${authToken}`
                }
            });
            const data = await response.json();
            setAccount(data);
            setIsLoading(false);
        } catch (error) {
            console.error('Error fetching account details:', error);
            setIsLoading(false);
        }
    };

    // Load initial account details when component mounts
    useEffect(() => {
        fetchAccountDetails();
    }, []);

    // Function to update account details on the API
    const updateAccountDetails = async () => {
        try {
            const authToken = localStorage.getItem('authToken');
            if (!authToken) throw new Error('No auth token found');

            const response = await fetch(`${backendUrl}/api/account/39`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${authToken}`
                },
                body: JSON.stringify(account)
            });
            if (response.ok) {
                console.log('Account details updated successfully');
            } else {
                console.error('Failed to update account details');
            }
        } catch (error) {
            console.error('Error updating account details:', error);
        }
    };

    // Function to handle input change
    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setAccount(prevAccount => ({
            ...prevAccount,
            [name]: value
        }));
    };

    return (
        <div>
            <h1>Account Details</h1>
            {isLoading ? (
                <p>Loading...</p>
            ) : (
                <div>
                    <label>Email:</label>
                    <input type="text" name="email" value={account.email} onChange={handleInputChange} />

                    <label>Name:</label>
                    <input type="text" name="name" value={account.name} onChange={handleInputChange} />

                    <label>Invitation Code:</label>
                    <input type="text" name="invitation_code" value={account.invitation_code} onChange={handleInputChange} />

                    <label>Address:</label>
                    <input type="text" name="address" value={account.address} onChange={handleInputChange} />

                    <button onClick={updateAccountDetails}>Update Details</button>
                </div>
            )}
        </div>
    );
}

export default NameViewer;
