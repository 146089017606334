import React, { useEffect, useState } from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEnvelope, faMapMarkerAlt, faPhone} from "@fortawesome/free-solid-svg-icons";
import {Link} from "react-router-dom";

const CallingCard = (data) => {


return (
    <div style={styles.card}>
        <h2 style={styles.company}>{data.company}</h2>
        <p style={styles.name}>{data.name}</p>
        <p style={styles.title}>{data.title}</p>

        <p style={styles.contact}>
            <FontAwesomeIcon icon={faPhone}/> {data.phone}
        </p>
        <p style={styles.contact}>
            <FontAwesomeIcon icon={faEnvelope}/> <Link to={`mailto:${data.email}`}>{data.email}</Link>
        </p>
        <p style={styles.address}><FontAwesomeIcon icon={faMapMarkerAlt}/> {data.address}</p>
    </div>
);

};

const styles = {
  card: {
    border: '1px solid #ccc',
    borderRadius: '8px',
    padding: '16px',
    maxWidth: '350px',
    minWidth: '350px',
    boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
    backgroundImage: "url('/images/bgdavidclark.png')",
    backgroundSize: 'cover', // Ensure the image covers the entire background
    backgroundPosition: 'center', // Center the image
    backgroundRepeat: 'no-repeat', // Prevent the image from repeating
  },
  name: {
    margin: '0 0 0px',
    fontSize: '20px',
    color: '#0088cd',
    textAlign: 'left',
  },
  title: {

    fontSize: '10px',
    color: '#fff',
    textAlign: 'left',
  },
  company: {
    margin: '0 0 15px',
    fontSize: '16px',
    color: '#fff',
    textAlign: 'left',
  },
  contact: {
    fontSize: '14px',
    textAlign: 'right',
    marginBottom: '10px'
  },
  address: {
    marginTop: 40,
    fontSize: '14px',
    color: '#000000',
    textAlign: 'left',
  },
};

export default CallingCard;
