import React, {useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import config from '../config';
import ChatApp from "../components/message/ChatApp";
const {backendUrl} = config;

const MessagePage = () => {
    const {receiver_id} = useParams();
    return (
        <div className="content flex font-sans antialiased ">
            <ChatApp receiver_id={receiver_id}></ChatApp>
        </div>
    );
};

export default MessagePage;
