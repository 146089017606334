import React, {useState} from 'react';
import {useNavigate, Link} from 'react-router-dom';
import GoogleLoginComponent from "../components/GoogleLoginComponent";
import config from '../config';

const {backendUrl} = config;
const LoginPage = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [rememberMe, setRememberMe] = useState(false);
    const navigate = useNavigate();
    const [error, setError] = useState(''); // Add error state

    const handleSubmit = (e) => {
        e.preventDefault();

        fetch(`${backendUrl}/api/account/login`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({email, password}),
        })
            .then(async (res) => {
                if (res.ok) {
                    const data = await res.json();
                    // Assume the response contains a token
                    localStorage.setItem('authToken', data.token);
                    localStorage.setItem('userEmail', data.email);
                    localStorage.setItem('name', data.name);
                    localStorage.setItem('userId', data.id);
                    navigate('/home');
                } else if (res.status === 400) {
                    const errorData = await res.json();
                    setError(errorData.detail || 'Signup failed. Please try again.');
                } else {
                    console.error('Login failed:', res.statusText);
                    // Handle other errors, show error message to user
                }
            })
            .catch((error) => {
                console.error('Login error:', error);
                // Handle network errors, show error message to user
            });
    };


    return (
        <div>
            <div
                className="flex flex-col sm:flex-row items-center md:items-start sm:justify-center md:justify-start flex-auto min-w-0 h-screen  bg-white">
                <div
                    className="md:flex md:items-center md:justify-end w-full sm:w-auto md:h-full md:w-1/2 py-8 px-4 sm:p-12 md:p-16 sm:rounded-2xl md:rounded-none sm:shadow md:shadow-none sm:bg-card ng-tns-c775625097-1">
                    <div className="w-full max-w-80 sm:w-80 mx-auto sm:mx-0 ">
                        <div className=""><Link to="/"><img src="/whooks.png" alt="Whooks Logo"/></Link></div>
                        <div className="text-2xl font-bold text-gray-700 mt-4 mb-4">Login</div>
                        <div className="h-14 ">
                            Don't have an account? <Link to="/signup" className="text-blue-600 hover:underline">Sign
                            up</Link>
                            {error && <div className="mb-4 text-red-600">{error}</div>} </div>
                        <form onSubmit={handleSubmit}>
                            <div className="mb-4">
                                <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                                    Email Address
                                </label>
                                <input
                                    type="email"
                                    id="email"
                                    className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring focus:border-blue-300"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    required
                                />
                            </div>
                            <div className="mb-4">
                                <label htmlFor="password"
                                       className="block text-sm font-medium text-gray-600">Password:</label>
                                <input
                                    type="password"
                                    id="password"
                                    name="password"
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                    className="mt-1 block w-full px-3 py-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                    required
                                />
                            </div>
                            <div className="flex items-center mb-4">
                                <input
                                    type="checkbox"
                                    id="remember-me"
                                    className="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded"
                                    checked={rememberMe}
                                    onChange={(e) => setRememberMe(e.target.checked)}
                                />
                                <label htmlFor="remember-me" className="ml-2 block text-sm text-gray-900">Remember
                                    me</label>
                                <Link to="/forgot-password" className="ml-auto text-sm text-gray-600 hover:underline">Forgot
                                    password?</Link>
                            </div>
                            <button type="submit"
                                    className="w-full px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500">
                                Login
                            </button>

                        </form>
                        <div className="mt-6 text-center justify-center items-center">
                            <p className="text-sm text-gray-600">Or continue with</p>
                            <div className="mt-6 ">
                                <GoogleLoginComponent/>
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    className="relative hidden md:flex flex-auto items-center justify-center h-full p-16 lg:px-28 overflow-hidden bg-gray-800 dark:border-l ng-tns-c2176845880-1">
                    <svg viewBox="0 0 960 540" width="100%" height="100%" preserveAspectRatio="xMidYMax slice"
                         xmlns="http://www.w3.org/2000/svg"
                         className="absolute inset-0 pointer-events-none ng-tns-c2176845880-1">
                        <g fill="none" stroke="currentColor" strokeWidth="100"
                           className="text-gray-700 opacity-25 ng-tns-c2176845880-1">
                            <circle r="234" cx="196" cy="23" className="ng-tns-c2176845880-1"></circle>
                            <circle r="234" cx="790" cy="491" className="ng-tns-c2176845880-1"></circle>
                        </g>
                    </svg>
                    <svg viewBox="0 0 220 192" width="220" height="192" fill="none"
                         className="absolute -top-16 -right-16 text-gray-700 ng-tns-c2176845880-1">
                        <defs className="ng-tns-c2176845880-1">
                            <pattern id="837c3e70-6c3a-44e6-8854-cc48c737b659" x="0" y="0" width="20" height="20"
                                     patternUnits="userSpaceOnUse" className="ng-tns-c2176845880-1">
                                <rect x="0" y="0" width="4" height="4" fill="currentColor"
                                      className="ng-tns-c2176845880-1"></rect>
                            </pattern>
                        </defs>
                        <rect width="220" height="192" fill="url(#837c3e70-6c3a-44e6-8854-cc48c737b659)"
                              className="ng-tns-c2176845880-1"></rect>
                    </svg>

                    <div className="z-10">
                        <div className="text-6xl font-bold leading-none text-gray-100 ng-tns-c2176845880-1">
                            <div className="">Welcome to</div>
                            <div className="">Whooks</div>
                        </div>
                        <div className="mt-4 text-gray-400">The app to get to know someone better by asking and
                            answering questions!
                        </div>

                        <div className="flex items-center mt-8 ng-tns-c2176845880-1">
                            <div className="flex flex-0 items-center -space-x-1.5 ng-tns-c2176845880-1">
                                <img src="/assets/images/avatars/female-01.png"
                                     className="flex-0 w-10 h-10 rounded-full ring-4 ring-offset-1 ring-gray-800 ring-offset-gray-800 object-cover ng-tns-c2176845880-1"/>
                                <img src="/assets/images/avatars/female-02.png"
                                     className="flex-0 w-10 h-10 rounded-full ring-4 ring-offset-1 ring-gray-800 ring-offset-gray-800 object-cover ng-tns-c2176845880-1"/>
                                <img src="/assets/images/avatars/male-01.png"
                                     className="flex-0 w-10 h-10 rounded-full ring-4 ring-offset-1 ring-gray-800 ring-offset-gray-800 object-cover ng-tns-c2176845880-1"/>
                                <img
                                    className="flex-0 w-10 h-10 rounded-full ring-4 ring-offset-1 ring-gray-800 ring-offset-gray-800 object-cover ng-tns-c2176845880-1"
                                    src="/assets/images/avatars/male-02.png"/>
                            </div>
                            <div className="ml-4 font-medium tracking-tight text-gray-400 ng-tns-c2176845880-1"> More
                                than 17k people joined us, it's your turn
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    );
};

export default LoginPage;
