import React, {useState, useEffect} from 'react';

const ToggleSwitch = ({id, checked, onChange}) => {
    const [isChecked, setIsChecked] = useState(checked);

    useEffect(() => {
        // Update the state when the checked prop changes
        setIsChecked(checked);
    }, [checked]);

    const handleToggle = () => {
        const newCheckedState = !isChecked;
        setIsChecked(newCheckedState);
        if (onChange) {
            onChange(newCheckedState);
        }
    };

    return (
        <div className="flex items-center">
            <input
                type="checkbox"
                id={id}
                className="sr-only"
                checked={isChecked}
                onChange={handleToggle}
            />
            <label
                htmlFor={id}
                className={`block w-14 h-6 rounded-full cursor-pointer transition-colors ${
                    isChecked ? 'bg-green-500' : 'bg-gray-300'
                }`}
            >
                <span
                    className={`block w-5 h-5 bg-white rounded-full shadow transform transition-transform ${
                        isChecked ? 'translate-x-8' : 'translate-x-1'
                    }`}
                ></span>
            </label>
        </div>
    );
};

export default ToggleSwitch;
