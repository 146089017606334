// src/CallingCard.js
import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone, faEnvelope,faMapMarkerAlt  } from '@fortawesome/free-solid-svg-icons';
import {Link} from "react-router-dom";
const CallingCard = (data) => {


  return (
      <div className="card-portrait" style={{  backgroundImage: "url('/images/bgredblack.png')",}}>
        <h2 style={styles.company}>{data.company}</h2>
        <p style={styles.name}>{data.name}</p>
        <p style={styles.title}>{data.title}</p>
        <p style={styles.contact}>
          <FontAwesomeIcon icon={faPhone}/> {data.phone}
        </p>
        <p style={styles.email}>
            <FontAwesomeIcon icon={faEnvelope}/> <Link to={`mailto:${data.email}`}>{data.email}</Link>
        </p>
        <p style={styles.address}><FontAwesomeIcon icon={faMapMarkerAlt}/> {data.address}</p>
      </div>

  );
};

const styles = {
    company: {
    margin: '60px 0 0px',
    fontSize: '15px',
    color: '#000000',
    textAlign: 'center',
    fontWeight: 'bold',
  },

  name: {
    margin: '150px 0 0',
    fontSize: '25px',
    color: '#ffffff',
    textAlign: 'center',
  },
  title: {
    fontSize: '10px',
    color: '#ffffff',
    textAlign: 'center',
  },

  contact: {
    margin: '30px 0 0px',
    fontSize: '14px',
    textAlign: 'left',
    color: '#000000',
  },

  email: {
    margin: '5px 0 0px',
    fontSize: '14px',
    textAlign: 'left',
    color: '#000000',
  },

  address: {
    margin: '5px 0 0px',
    fontSize: '14px',
    color: '#000000',
    textAlign: 'left',
  },
};

export default CallingCard;
