import React, {useState} from 'react';
import config from '../../config';
import {useNavigate} from "react-router-dom";

const {backendUrl} = config;

const SecurityForm = () => {
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [loading, setLoading] = useState(false);
    const [passwordError, setPasswordError] = useState('');
    const [confirmPasswordError, setConfirmPasswordError] = useState('');
    const [error, setError] = useState(''); // Add error state
    const handleChangePassword = async (e) => {
        e.preventDefault();
        setLoading(true); // Set loading state to true when submitting the form
        try {
            const authToken = localStorage.getItem('authToken');
            if (!authToken) throw new Error('No auth token found');

            const response = await fetch(`${backendUrl}/api/account/change-password/`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${authToken}`,
                },
                body: JSON.stringify({password, confirmPassword}), // Send both current and new passwords in the request body
            });

            if (!response.ok) throw new Error('Failed to change password');

            // Handle success response here
            alert('Password changed successfully');
        } catch (error) {
            console.error('Error changing password:', error);
            // Handle error here
            alert('Failed to change password. Please try again later.');
        } finally {
            setLoading(false); // Set loading state back to false after request completes
        }
    };

   const handlePasswordChange = (e) => {
        const value = e.target.value;
        setPasswordError(''); // Clear previous password error
        setPassword(value); // Update the new password state
        // Perform validation
        if (value.length < 8) {
            setPasswordError('Password must be at least 8 characters long.');
        }
    };

    const handleConfirmPasswordChange = (e) => {
        const value = e.target.value;
        setConfirmPassword(value);
        if (value !== password) {
            setConfirmPasswordError('Passwords do not match.');
        } else {
            setConfirmPasswordError('');
        }
    };

    return (
        <div>
            <div className="p-8">
                <form onSubmit={handleChangePassword}>
                    <div className="border-b-2 pb-6 mb-6">
                        <div className="pb-4">
                            <div className="text-2xl font-bold pb-6 ">Security</div>
                            <div className="font-semibold">Change your password</div>
                            <div className="text-gray-500">You can only change your password twice within 24 hours!
                            </div>
                        </div>
                        <div className="mb-4">
                            <label htmlFor="password" className="block text-sm font-medium text-gray-700">
                                Create a Password
                            </label>
                            <input
                                type="password"
                                id="password"
                                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring focus:border-blue-300"
                                value={password}
                                onChange={handlePasswordChange}
                                required
                            />
                            {passwordError && <p className="text-red-600 text-sm">{passwordError}</p>}
                        </div>
                        <div className="mb-6">
                            <label htmlFor="confirmPassword" className="block text-sm font-medium text-gray-700">
                                Re-enter Password
                            </label>
                            <input
                                type="password"
                                id="confirmPassword"
                                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring focus:border-blue-300"
                                value={confirmPassword}
                                onChange={handleConfirmPasswordChange}
                                required
                            />
                            {confirmPasswordError && <p className="text-red-600 text-sm">{confirmPasswordError}</p>}
                        </div>
                    </div>
                    <div className="flex justify-end space-x-4">
                        <button
                            type="button"
                            className="px-4 py-2 bg-gray-300 text-gray-700 rounded-md hover:bg-gray-400 focus:outline-none focus:ring-2 focus:ring-gray-500"
                            onClick={() => {
                                setPassword(''); // Reset current password field
                                setConfirmPassword(''); // Reset new password field
                            }}
                        >
                            Cancel
                        </button>
                        <button
                            type="submit"
                            className={`px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 ${loading ? 'opacity-50 cursor-not-allowed' : ''}`}
                            disabled={loading} // Disable the button when loading is true
                        >
                            {loading ? 'Saving...' : 'Save'}
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default SecurityForm;
