import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import AdamCallingCard from "../components/callingcard/AdamCallingCard";
import KenCallingCard from "../components/callingcard/KenCallingCard";
import WhoosoCallingCard from "../components/callingcard/WhoosoCallingCard";
import CherylHardyCallingCard from "../components/callingcard/CherylHardyCallingCard";
import MarkHaymeCallingCard from "../components/callingcard/MarkHaymeCallingCard";
import AlexisAndresCallingCard from "../components/callingcard/AlexisAndresCallingCard";
import CharleneCallingCard from "../components/callingcard/CharleneCallingCard";
import config from '../config';

const { backendUrl } = config;

const componentMap = {
    "AdamCallingCard": AdamCallingCard,
    "KenCallingCard": KenCallingCard,
    "WhoosoCallingCard": WhoosoCallingCard,
    "CherylHardyCallingCard": CherylHardyCallingCard,
    "MarkHaymeCallingCard": MarkHaymeCallingCard,
    "AlexisAndresCallingCard": AlexisAndresCallingCard,
    "CharleneCallingCard": CharleneCallingCard,
};

const HomePage = () => {
    const [cardData, setCardData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const authToken = localStorage.getItem('authToken');
                if (!authToken) throw new Error('No auth token found');

                const response = await fetch(`${backendUrl}/api/cards`, {
                    headers: {
                        Authorization: `Bearer ${authToken}`
                    }
                });
                if (!response.ok) throw new Error('Failed to fetch data');

                const data = await response.json();
                if (Array.isArray(data)) {
                    setCardData(data);
                } else {
                    throw new Error('Fetched data is not an array');
                }
            } catch (error) {
                console.error('Error fetching data:', error);
                setError(error.message);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, []);

    if (loading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>Error: {error}</div>;
    }

    return (
        <div className="min-h-screen flex font-sans antialiased">
            <div className="flex-1">
                <div className="">
                    <div className="bg-white">
                        <div className="flex justify-center items-center mb-6">
                        </div>
                        <div className="text-center bg-blue-50">
                            <div className="flex flex-wrap justify-center pt-5">
                                {cardData.map((item, index) => {
                                    const Component = componentMap[item.theme] || DefaultCallingCard;
                                    return (
                                        <Link
                                            to={`/connect/${item.id}`}
                                            key={index}
                                            className="flex justify-center items-center p-2 m-2 bg-white rounded"
                                        >
                                            <Component {...item} />
                                        </Link>
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

// Default component to render if theme is not found in componentMap
const DefaultCallingCard = ({ name, title, company, address, phone, email }) => (
    <div className="p-4 bg-gray-100 rounded shadow">
        <h3>{name}</h3>
        <p>{title}</p>
        <p>{company}</p>
        <p>{address}</p>
        <p>{phone}</p>
          <p><a href={`mailto:${email}`}>{email}</a></p>
    </div>
);

export default HomePage;
