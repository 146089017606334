import React, {useState, useEffect} from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faLock, faBell, faUser} from '@fortawesome/free-solid-svg-icons';
import AccountForm from "../components/forms/AccountForm";
import SecurityForm from "../components/forms/SecurityForm";
import NotificationForm from "../components/forms/NotificationForm";
import CardForm from "../components/forms/CardForm";

const SettingsPage = () => {
    const [selectedSetting, setSelectedSetting] = useState('Account');

    const settings = [
        {
            name: 'Account',
            description: 'Manage your account information and settings.',
            icon: <FontAwesomeIcon icon={faUser}/>, // Account icon
            content: (
                <AccountForm></AccountForm>
            ),
        },
          {
            name: 'Card',
            description: 'Manage your card information and settings.',
            icon: <FontAwesomeIcon icon={faUser}/>, // Account icon
            content: (
                <CardForm/>
            ),
        },
        {
            name: 'Security',
            description: 'Update your security settings and passwords.',
            icon: <FontAwesomeIcon icon={faLock}/>, // Security icon
            content: (<SecurityForm/>
            ),
        },

        {
            name: 'Notifications',
            description: 'Customize your notification preferences.',
            icon: <FontAwesomeIcon icon={faBell}/>, // Notifications icon
            content: (<NotificationForm/>),
        },

    ];


    const selectedContent = settings.find(setting => setting.name === selectedSetting)?.content;

    return (
        <div className="content flex items-center justify-center bg-gray-100">
            <div className="flex w-full h-full bg-white ">
                <div className="w-1/3 border-r">
                    <h2 className="text-3xl font-bold m-4 p-4">Settings</h2>
                    <ul>
                        {settings.map((setting) => (
                            <li
                                key={setting.name}
                                className={`mb-4 cursor-pointer p-4 ${
                                    selectedSetting === setting.name ? 'menu-select' : ''
                                }`}
                                onClick={() => setSelectedSetting(setting.name)}
                            >
                                <div className="flex items-center">
                                    <span className="mr-2 text-gray-500 p-0 -mt-5">{setting.icon}</span>
                                    <div>
                                        <h3 className="font-semibold">{setting.name}</h3>
                                        <p className="text-gray-600">{setting.description}</p>
                                    </div>
                                </div>
                            </li>
                        ))}
                    </ul>
                </div>
                <div className="w-2/3 p-4 bg-gray-100">{selectedContent}</div>
            </div>
        </div>
    );
};


export default SettingsPage;
