import React, {useState, useEffect} from 'react';
import ToggleSwitch from '../atom/ToggleSwitch';
import config from '../../config';
import Alert from "../atom/Alert";

const {backendUrl} = config;
const NotificationForm = () => {
    const [email_on_connect_request, setConnectRequests] = useState(false);
    const [email_on_news_updates, setNewsUpdates] = useState(false);
    const [alert, setAlert] = useState({show: false, type: '', message: ''});

    useEffect(() => {
        // Fetch notification settings data when component mounts
        fetchData();
    }, []);

    const fetchData = async () => {
        try {
            const authToken = localStorage.getItem('authToken');
            if (!authToken) throw new Error('No auth token found');

            const response = await fetch(`${backendUrl}/api/account`, {
                headers: {
                    Authorization: `Bearer ${authToken}`
                }
            });

            if (!response.ok) throw new Error('Failed to fetch data');

            const data = await response.json();
            setConnectRequests(data.settings.email_on_connect_request);
            setNewsUpdates(data.settings.email_on_news_updates);


        } catch (error) {
            console.error('Error fetching data:', error);
            // Handle error here
            alert('Failed to fetch notification settings. Please try again later.');
        }
    };

    const handleCancel = () => {
        // Reset toggle states to initial state
        setConnectRequests(false);
        setNewsUpdates(false);
    };

    const handleSave = async (e) => {
        e.preventDefault();
        try {
            const authToken = localStorage.getItem('authToken');
            if (!authToken) throw new Error('No auth token found');

            const response = await fetch(`${backendUrl}/api/account/settings`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${authToken}`,
                },
                body: JSON.stringify({
                        email_on_connect_request,
                        email_on_news_updates
                }),
            });

            if (!response.ok) throw new Error('Failed to save data');

            // Handle success response here
            setAlert({show: true, type: 'success', message: 'submitted successfully!'});
        } catch (error) {
            console.error('Error saving data:', error);
            setAlert({show: true, type: 'error', message: 'Failed to save profile. Please try again later.'});
        }
    };

    return (
        <div className="relative p-8">
            <Alert
                type={alert.type}
                message={alert.message}
                show={alert.show}
                onClose={() => setAlert({...alert, show: false})}
            />
            <form>
                <div className="pb-4">
                    <div className="text-2xl font-bold pb-6">Notifications</div>
                </div>
                <div className="border-b-2 pb-6 mb-6">
                    <div className="font-semibold text-lg pb-2">Email me when</div>
                    <div className="flex items-center justify-between pb-2">
                        <label htmlFor="email_on_connect_request">Someone requests to connect</label>
                        <ToggleSwitch
                            id="email_on_connect_request"
                            checked={email_on_connect_request}
                            onChange={() => setConnectRequests(!email_on_connect_request)}
                        />
                    </div>
                    <div className="flex items-center justify-between">
                        <label htmlFor="email_on_news_updates">News,
                            announcements, and product updates</label>
                        <ToggleSwitch
                            id="email_on_news_updates"
                            checked={email_on_news_updates}
                            onChange={() => setNewsUpdates(!email_on_news_updates)}
                        />
                    </div>
                </div>
                <div className="flex justify-end space-x-4">
                    <button
                        type="button"
                        className="px-4 py-2 bg-gray-300 text-gray-700 rounded-md hover:bg-gray-400 focus:outline-none focus:ring-2 focus:ring-gray-500"
                        onClick={handleCancel}
                    >
                        Cancel
                    </button>
                    <button
                        type="submit"
                        className="px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500"
                        onClick={handleSave}
                    >
                        Save
                    </button>
                </div>
            </form>
        </div>
    );
};

export default NotificationForm;
