import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { getData, postData } from '../../api/crud';
import MessageList from './MessageList';
import MessageInput from './MessageInput';
import RoomList from "./RoomList";

const ChatApp = () => {
  const { roomId } = useParams();
  const [messages, setMessages] = useState([]);
  const loginUserId = parseInt(localStorage.getItem('userId'));
  const messageEndRef = useRef(null);

  useEffect(() => {
    fetchMessages();
    const interval = setInterval(fetchMessages, 3000); // Fetch messages every 3 seconds

    return () => clearInterval(interval); // Clear interval on component unmount
  }, [roomId]);

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const fetchMessages = async () => {
    const data = await getData(`/room/${roomId}`);
    if (data) {
      setMessages(data);
      console.log('Messages fetched:', data);
    } else {
      console.error('Failed to fetch messages.');
    }
  };

  const handleSendMessage = async (content) => {
    const payload = {
      content: content,
      room_id: roomId,
    };

    const response = await postData('message', payload);
    if (response) {
      fetchMessages(); // Refresh the messages list after sending a message
    } else {
      console.error('Failed to send message.');
    }
  };

  const scrollToBottom = () => {
    if (messageEndRef.current) {
      messageEndRef.current.scrollIntoView({ behavior: 'instant' });
    }
  };

  return (
    <div className="flex w-full">
      {/* Column 1: RoomList */}
      <div className="w-1/4">
        <RoomList />
      </div>
      {/* Column 2: MessageList and MessageInput */}
      <div className="w-3/4 p-4 bg-gray-200 flex flex-col justify-between">
        <div className="mb-4 overflow-y-auto flex-grow">
          <MessageList messages={messages} />
          <div ref={messageEndRef} />
        </div>
        <div>
          <MessageInput onSendMessage={handleSendMessage} />
        </div>
      </div>
    </div>
  );
};

export default ChatApp;
