import React, { useState, useEffect } from 'react';
import axios from 'axios'; // Import Axios for making HTTP requests
import config from '../../config';
import { getData, postData } from '../../api/crud';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPeopleGroup,faUser} from "@fortawesome/free-solid-svg-icons";
const { backendUrl } = config;


const ConnectButton = ({ receiver_id }) => {
  const [requestData, setRequestData] = useState(null);
  const userId = localStorage.getItem('userId');

  useEffect(() => {
    // Fetch request data when the component mounts
    fetchRequestData();
  }, []);

  const fetchRequestData = () => {
    // Fetch request data from the backend
    axios.get(`${backendUrl}/api/connection/status/`, {
      params: {
        sender_id: userId,
        receiver_id: receiver_id
      }
    })
    .then(response => {
      setRequestData(response.data);
    })
    .catch(error => {
      console.error('Error fetching request data:', error);
    });
  };

  const handleClick = () => {
    if (!requestData) {
      // Send a new connection request if requestData is null
      sendRequest();
    } else {
      if (requestData.status === "pending" && requestData.receiver_id === parseInt(userId)) {
        // Accept the request if status is pending and user is the receiver
        acceptRequest();
      } else if (requestData.status === "pending") {
        // Cancel the request if status is pending and user is the sender
        cancelRequest();
      } else if (requestData.status === "cancelled") {
        // Reactivate the cancelled request if user is the sender
        pendingRequest();
      }

      // You can handle other cases here if needed
    }
  };

  const cancelRequest = () => {
    const requestDataToUpdate = {
      ...requestData,
      status: "cancelled"
    };

    // Make API request using Axios to update the request data
    axios.put(`${backendUrl}/api/connection/${requestData.id}`, requestDataToUpdate)
    .then(response => {
      console.log('Request canceled successfully:', response.data);
      // Update request data
      setRequestData({ ...requestData, status: "cancelled" });
    })
    .catch(error => {
      console.error('Error canceling request:', error);
    });
  };

  const pendingRequest = () => {
    const requestDataToUpdate = {
      ...requestData,
      status: "pending"
    };

    // Make API request using Axios to update the request data
    axios.put(`${backendUrl}/api/connection/${requestData.id}`, requestDataToUpdate)
    .then(response => {
      console.log('Request reactivated successfully:', response.data);
      // Update request data
      setRequestData({ ...requestData, status: "pending" });
    })
    .catch(error => {
      console.error('Error reactivating request:', error);
    });
  };

  const acceptRequest = () => {
    const requestDataToUpdate = {
      ...requestData,
      status: "accepted"
    };

    // Make API request using Axios to update the request status to "accepted"
    axios.put(`${backendUrl}/api/connection/${requestData.id}`, requestDataToUpdate)
    .then(response => {
      console.log('Request accepted successfully:', response.data);
      // Update request data
      setRequestData({ ...requestData, status: "accepted" });
    })
    .catch(error => {
      console.error('Error accepting request:', error);
    });
  };

  const sendRequest = async () => {
    const payload = {
      sender_id: userId,
      receiver_id: receiver_id,
      status: "pending"
    };
    const response = await postData('connection/', payload);
        if (response) {
            setRequestData(response);
        } else {
            console.error('Failed to send message.');
        }


  };


  // Determine button label based on request status and user role
  let buttonLabel = " Connect";
  if (requestData && requestData.status === "pending") {
    if (requestData.receiver_id === parseInt(userId) ) {
      buttonLabel = "Accept";
    } else {
      buttonLabel = "Cancel Request";
    }
  } else if (requestData && requestData.status === "accepted") {
    buttonLabel = "Accepted";
  }

  return (
    <button className="w-full h-16 bg-orange-500  text-xl text-white " onClick={handleClick}>
    <FontAwesomeIcon icon={faUser} className="text-2xl text-gray-200 mr-1" />
    {buttonLabel}
</button>

  );
};

export default ConnectButton;
