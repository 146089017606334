import React, {useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import config from '../config';
import AdamCallingCard from "../components/callingcard/AdamCallingCard";
import KenCallingCard from "../components/callingcard/KenCallingCard";
import WhoosoCallingCard from "../components/callingcard/WhoosoCallingCard";
import CherylHardyCallingCard from "../components/callingcard/CherylHardyCallingCard";
import MarkHaymeCallingCard from "../components/callingcard/MarkHaymeCallingCard";
import AlexisAndresCallingCard from "../components/callingcard/AlexisAndresCallingCard";
import CharleneCallingCard from "../components/callingcard/CharleneCallingCard";
import ConnectButton from "../components/atom/ConnectionButton";
import MessageButton from "../components/atom/MessageButton";
import C0001 from "../components/card/C0001";

const {backendUrl} = config;

const componentMap = {
    "AdamCallingCard": AdamCallingCard,
    "KenCallingCard": C0001,
    "WhoosoCallingCard": WhoosoCallingCard,
    "CherylHardyCallingCard": CherylHardyCallingCard,
    "MarkHaymeCallingCard": MarkHaymeCallingCard,
    "AlexisAndresCallingCard": AlexisAndresCallingCard,
    "CharleneCallingCard": CharleneCallingCard,
};

const SoloCardPage = () => {
    const [isPersonalInfoHidden, setIsPersonalInfoHidden] = useState(true);
    const [isInterestsHidden, setIsInterestsHidden] = useState(true);
    const {cardId} = useParams();
    const [cardData, setCardData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const authToken = localStorage.getItem('authToken');
                if (!authToken) throw new Error('No auth token found');

                const response = await fetch(`${backendUrl}/api/card/${cardId}`, {
                    headers: {
                        Authorization: `Bearer ${authToken}`
                    }
                });
                if (!response.ok) throw new Error('Failed to fetch data');

                const data = await response.json();
                if (data.permission === "accepted") {
                    setIsInterestsHidden(false);
                    setIsPersonalInfoHidden(false);
                }
                setCardData(data);
            } catch (error) {
                console.error('Error fetching data:', error);
                setError(error.message);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [cardId]);

    if (loading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>Error: {error}</div>;
    }

    if (!cardData) {
        return <div>Card not found</div>;
    }

    const Component = componentMap[cardData.theme] || DefaultCallingCard;
    console.log(cardData);
    return (
        <div className="relative content flex ">
            {/* For small screens, display the card centered */}
            <div className="w-full h-full sm:w-auto md:w-1/3 md:border-r-2 flex flex-col">
                <div
                    className="flex space-x-0 p-0 md:p-2 md:space-x-2 justify-center border-b-2 border-t-2 bottom-0-mobile md:mb-0">
                    <ConnectButton receiver_id={cardData.user_id}/>
                    <MessageButton receiver_id={cardData.user_id}/>
                </div>
                <div className="flex-1 order-first md:order-last ">
                    <Component {...cardData} />
                </div>
            </div>
            {/* For larger screens, display the card in a two-column layout */}
            <div className="hidden sm:block sm:w-1/2 p-4">
                {/* Personal Information */}
                <div className="p-10">
                    <div className="mb-4">
                        <h2 className={`text-xl font-bold mb-2 cursor-pointer`}>Personal Information</h2>
                        <h2 className={`text-xl font-bold mb-2 cursor-pointer ${isPersonalInfoHidden ? 'blur' : 'hidden'}`}
                            onClick={() => setIsPersonalInfoHidden(!isPersonalInfoHidden)}>Personal Information</h2>
                        {!isPersonalInfoHidden && (
                            <div className={`blur-effect ${isPersonalInfoHidden ? 'hidden' : ''}`}>
                                <p>Name: {cardData.user.name}</p>
                                <p>Email: {cardData.user.email}</p>
                                <p>Phone: {cardData.phone}</p>
                                <p>Location:{cardData.user.address}</p>
                            </div>
                        )}
                    </div>
                    {/* Interests */}
                    <div>
                        <h2 className={`text-xl font-bold mb-2 cursor-pointer`}>Expertise and Interest</h2>
                        <h2 className={`text-xl font-bold mb-2 cursor-pointer ${isInterestsHidden ? 'blur' : ''}`}
                            onClick={() => setIsInterestsHidden(!isInterestsHidden)}>Interests</h2>
                        {!isInterestsHidden && (
                            <div className={`blur-effect ${isInterestsHidden ? 'hidden' : ''}`}>
                                <p>Interests: Technology, Travel, Photography</p>
                            </div>
                        )}
                    </div>
                    {/* Expertise */}
                    <div>
                        <h2 className={`text-xl font-bold mb-2 cursor-pointer ${isInterestsHidden ? 'blur' : ''}`}
                            onClick={() => setIsInterestsHidden(!isInterestsHidden)}>Expertise</h2>
                        {!isInterestsHidden && (
                            <div className={`blur-effect ${isInterestsHidden ? 'hidden' : ''}`}>
                                <p>Expertise: Web Development, Data Analysis, Project Management</p>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

// Default component to render if theme is not found in componentMap
const DefaultCallingCard = ({name, title, company, address, phone, email}) => (
    <div className="p-4 bg-gray-100 rounded shadow">
        <h3>{name}</h3>
        <p>{title}</p>
        <p>{company}</p>
        <p>{address}</p>
        <p>{phone}</p>
        <p>{email}</p>
    </div>
);

export default SoloCardPage;
