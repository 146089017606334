// src/CallingCard.js
import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone, faEnvelope,faMapMarkerAlt  } from '@fortawesome/free-solid-svg-icons';
import {Link} from "react-router-dom";
const CallingCard = (data) => {


  return (
      <div className="card-portrait" style={{  backgroundImage: "url('/images/bgwhitegreen.png')",}}>
        <h2 style={styles.company}>{data.company}</h2>
        <p style={styles.name}>{data.name}</p>
        <p style={styles.title}>{data.title}</p>

        <p style={styles.contact}>
          <FontAwesomeIcon icon={faPhone}/> {data.phone}
        </p>
        <p style={styles.email}>
          <FontAwesomeIcon icon={faEnvelope}/> <Link to={`mailto:${data.email}`}>{data.email}</Link>
        </p>
        <p style={styles.address}><FontAwesomeIcon icon={faMapMarkerAlt}/> {data.address}</p>
      </div>

  );
};

const styles = {
  card: {
    border: '1px solid #ccc',
    borderRadius: '8px',
    padding: '16px',
    width: '300px', /* Adjust width for portrait */
    height: '450px', /* Adjust height for portrait */
    margin: '20px auto',
    boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',

    backgroundSize: 'cover', // Ensure the image covers the entire background
    backgroundPosition: 'center', // Center the image
    backgroundRepeat: 'no-repeat', // Prevent the image from repeating
  },
company: {
  margin: '20px auto', // Add margin to center horizontally
  fontSize: '20px',
  color: '#000000',
  textAlign: 'center',
  fontWeight: 'bold',
},

  name: {
    margin: '100px 0 0px',
    fontSize: '25px',
    color: '#000000',
    textAlign: 'center',
  },
  title: {
    fontSize: '10px',
    color: '#000000',
    textAlign: 'center',
  },

  contact: {
    margin: '70px 30px 10px',
    fontSize: '14px',
    textAlign: 'left',
    color: '#000000',
  },
  address: {
    margin: '5px 30px 0px',
    fontSize: '14px',
    color: '#000000',
    textAlign: 'left',
  },
};

export default CallingCard;
