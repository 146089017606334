import {BrowserRouter as Router, Routes, Route, Navigate, useLocation, Outlet} from 'react-router-dom';
import LoginPage from './pages/LoginPage';
import SignUpPage from './pages/SignUpPage';
import ForgotPasswordPage from './pages/ForgotPasswordPage';
import ResetPasswordPage from './pages/ResetPasswordPage';
import HomePage from './pages/HomePage';
import ProfilePage from './pages/ProfilePage';
import SettingsPage from './pages/SettingsPage';
import SoloCardPage from './pages/SoloCardPage';
import Header from './components/Header';
import Sample from "./pages/Sample";
import Message from "./pages/MessagePage";
import MessagePage from "./pages/MessagePage";

const PrivateRoutes = () => {
    const auth = {token: localStorage.getItem('authToken')};
    return auth.token ? <Outlet/> : <Navigate to='/login'/>;
}

const AppContent = () => {
    const location = useLocation();
    const isAuthRoute =
        location.pathname === '/login' ||
        location.pathname === '/signup' ||
        location.pathname === '/forgot-password' ||
        location.pathname === '/reset-password';

    return (
        <div className={!isAuthRoute ? 'max-w-7xl m-auto md:mt-5 bg-white' : ''}>
            {!isAuthRoute && <Header/>}
            <Routes>
                <Route element={<PrivateRoutes/>}>
                    <Route path="/" element={<HomePage/>}/>
                    <Route path="/home" element={<HomePage/>}/>
                    <Route path="/profile" element={<ProfilePage/>}/>
                    <Route path="/settings" element={<SettingsPage/>}/>
                    <Route path="/sample" element={<Sample/>}/>
                   <Route path="/message" element={<MessagePage/>}/>
                    <Route path="/message/:roomId" element={<MessagePage/>}/>
                    <Route path="/connect/:cardId" element={<SoloCardPage/>}/>
                </Route>
                <Route path="/login" element={<LoginPage/>}/>
                <Route path="/signup" element={<SignUpPage/>}/>
                <Route path="/forgot-password" element={<ForgotPasswordPage/>}/>
                <Route path="/reset-password" element={<ResetPasswordPage/>}/>
            </Routes>
        </div>
    );
}

function App() {
    return (
        <Router>
            <AppContent/>
        </Router>
    );
}

export default App;
