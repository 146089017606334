import React, {useState} from 'react';
import {useNavigate, Link} from 'react-router-dom';
import GoogleLoginComponent from "../components/GoogleLoginComponent";
import config from '../config';

const {backendUrl} = config;
const SignUpPage = () => {
    const [name, setDisplayName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [passwordError, setPasswordError] = useState('');
    const [confirmPasswordError, setConfirmPasswordError] = useState('');
    const navigate = useNavigate();
    const [error, setError] = useState(''); // Add error state


    const handlePasswordChange = (e) => {
        const value = e.target.value;
        setError(''); // Clear previous errors

        setPassword(value);
        if (value.length < 8) {
            setPasswordError('Password must be at least 8 characters long.');
        } else {
            setPasswordError('');
        }
    };

    const handleConfirmPasswordChange = (e) => {
        const value = e.target.value;
        setConfirmPassword(value);
        if (value !== password) {
            setConfirmPasswordError('Passwords do not match.');
        } else {
            setConfirmPasswordError('');
        }
    };

    const handleSignUp = (e) => {
        e.preventDefault();

        if (password.length < 8 || password !== confirmPassword) {
            return;
        }

        // Perform signup logic here (e.g., send data to backend)
        fetch(`${backendUrl}/api/account/signup`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({name, email, password}),
        })
            .then(async (res) => {
                if (res.ok) {
                    const data = await res.json();
                    // Store the token in localStorage
                    localStorage.setItem('authToken', data.token);
                    localStorage.setItem('userEmail', data.email);
                    localStorage.setItem('name', data.name);
                    localStorage.setItem('userId', data.id);
                    console.log('Backend response:', data);
                    navigate('/');
                } else if (res.status === 400) {
                    const data = await res.json();
                    console.error('Signup failed:', data.detail);
                    setError(data.detail || 'Signup failed. Please try again.');
                } else {
                    console.error('Signup failed:', res.statusText);

                }
            })
            .catch((error) => {
                console.error('Signup error:', error);
            });
    };

    return (
        <div>
            <div
                className="flex flex-col sm:flex-row items-center md:items-start sm:justify-center md:justify-start flex-auto min-w-0 h-screen bg-white">
                <div
                    className="md:flex md:items-center md:justify-end w-full sm:w-auto md:h-full md:w-1/2 py-8 px-4 sm:p-12 md:p-16 sm:rounded-2xl md:rounded-none sm:shadow md:shadow-none sm:bg-card">
                    <div className="w-full max-w-80 sm:w-80 mx-auto sm:mx-0">
                        <div className="mb-8"><Link to="/"><img src="/whooks.png"/></Link></div>
                        <div className="mt-4 mb-4">
                            <div className="text-2xl font-bold text-gray-700">Sign up</div>
                            <div>Already have an account? <Link to="/login" className="text-blue-600 hover:underline">Sign
                                in</Link></div>
                        </div>

                        {error && <div className="mb-4 text-red-600">{error}</div>}

                        <form onSubmit={handleSignUp}>
                            <div className="mb-4">
                                <label htmlFor="displayName" className="block text-sm font-medium text-gray-700">
                                    Display Name
                                </label>
                                <input
                                    type="text"
                                    id="displayName"
                                    className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring focus:border-blue-300"
                                    value={name}
                                    onChange={(e) => setDisplayName(e.target.value)}
                                    required
                                />
                            </div>
                            <div className="mb-4">
                                <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                                    Email Address
                                </label>
                                <input
                                    type="email"
                                    id="email"
                                    className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring focus:border-blue-300"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    required
                                />
                            </div>
                            <div className="mb-4">
                                <label htmlFor="password" className="block text-sm font-medium text-gray-700">
                                    Create a Password
                                </label>
                                <input
                                    type="password"
                                    id="password"
                                    className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring focus:border-blue-300"
                                    value={password}
                                    onChange={handlePasswordChange}
                                    required
                                />
                                {passwordError && <p className="text-red-600 text-sm">{passwordError}</p>}
                            </div>
                            <div className="mb-6">
                                <label htmlFor="confirmPassword" className="block text-sm font-medium text-gray-700">
                                    Re-enter Password
                                </label>
                                <input
                                    type="password"
                                    id="confirmPassword"
                                    className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring focus:border-blue-300"
                                    value={confirmPassword}
                                    onChange={handleConfirmPasswordChange}
                                    required
                                />
                                {confirmPasswordError && <p className="text-red-600 text-sm">{confirmPasswordError}</p>}
                            </div>
                            <div className="mb-6">
                                <label className="flex items-center">
                                    <input type="checkbox" className="form-checkbox" required/>
                                    <span className="ml-2 text-sm text-gray-700">I agree with <a href="#"
                                                                                                 className="text-blue-600 hover:underline">Terms</a> and <a
                                        href="#" className="text-blue-600 hover:underline">Privacy Policy</a></span>
                                </label>
                            </div>
                            <button
                                type="submit"
                                className="w-full py-2 px-4 bg-blue-600 text-white rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                            >
                                Sign Up
                            </button>
                        </form>
                        <div className="mt-6 text-center justify-center items-center">
                            <p className="text-sm text-gray-600">Or continue with</p>
                            <div className="mt-6">
                                <GoogleLoginComponent/>
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    className="relative hidden md:flex flex-auto items-center justify-center h-full p-16 lg:px-28 overflow-hidden bg-gray-800 dark:border-l">
                    <svg viewBox="0 0 960 540" width="100%" height="100%" preserveAspectRatio="xMidYMax slice"
                         xmlns="http://www.w3.org/2000/svg" className="absolute inset-0 pointer-events-none">
                        <g fill="none" stroke="currentColor" strokeWidth="100" className="text-gray-700 opacity-25">
                            <circle r="234" cx="196" cy="23"></circle>
                            <circle r="234" cx="790" cy="491"></circle>
                        </g>
                    </svg>
                    <svg viewBox="0 0 220 192" width="220" height="192" fill="none"
                         className="absolute -top-16 -right-16 text-gray-700">
                        <defs>
                            <pattern id="837c3e70-6c3a-44e6-8854-cc48c737b659" x="0" y="0" width="20" height="20"
                                     patternUnits="userSpaceOnUse">
                                <rect x="0" y="0" width="4" height="4" fill="currentColor"></rect>
                            </pattern>
                        </defs>
                        <rect width="220" height="192" fill="url(#837c3e70-6c3a-44e6-8854-cc48c737b659)"></rect>
                    </svg>
                    <div className="z-10">
                        <div className="text-6xl font-bold leading-none text-gray-100">
                            <div>Welcome to</div>
                            <div>Whooks</div>
                        </div>
                        <div className="mt-4 text-gray-400">The app to get to know someone better by asking and
                            answering questions!
                        </div>
                        <div className="flex items-center mt-8">
                            <div className="flex flex-0 items-center -space-x-1.5">
                                <img src="/assets/images/avatars/female-01.png"
                                     className="flex-0 w-10 h-10 rounded-full ring-4 ring-offset-1 ring-gray-800 ring-offset-gray-800 object-cover"/>
                                <img src="/assets/images/avatars/female-02.png"
                                     className="flex-0 w-10 h-10 rounded-full ring-4 ring-offset-1 ring-gray-800 ring-offset-gray-800 object-cover"/>
                                <img src="/assets/images/avatars/female-03.png"
                                     className="flex-0 w-10 h-10 rounded-full ring-4 ring-offset-1 ring-gray-800 ring-offset-gray-800 object-cover"/>
                                <img src="/assets/images/avatars/female-04.png"
                                     className="flex-0 w-10 h-10 rounded-full ring-4 ring-offset-1 ring-gray-800 ring-offset-gray-800 object-cover"/>
                                <img src="/assets/images/avatars/male-01.png"
                                     className="flex-0 w-10 h-10 rounded-full ring-4 ring-offset-1 ring-gray-800 ring-offset-gray-800 object-cover"/>
                            </div>
                            <div className="ml-4 text-gray-400">Join 5,000+ users</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SignUpPage;
