import React from 'react';
import moment from 'moment-timezone';
import countryTimezones from 'country-timezones'; // Import the country-timezones library

const MessageList = ({ messages }) => {
  const loginUserId = parseInt(localStorage.getItem('userId'));

  const calculateHoursSinceMessage = (timestamp) => {
    const messageTimeUTC = moment.utc(timestamp); // Parse UTC timestamp using moment
    const userCountryCode = 'PH'; // Placeholder for user's country code (e.g., retrieved from user's profile or location)
    const userTimeZone = getUserTimeZone(userCountryCode); // Get user's time zone based on country code

    // Convert UTC timestamp to the user's local time zone
    const messageTimeLocal = messageTimeUTC.tz(userTimeZone);

    const now = moment(); // Current time in user's local time zone
    const elapsedMilliseconds = now - messageTimeLocal;
    const elapsedSeconds = Math.floor(elapsedMilliseconds / 1000);
    const elapsedMinutes = Math.floor(elapsedSeconds / 60);
    const elapsedHours = Math.floor(elapsedMinutes / 60);

    if (elapsedSeconds < 60) {
      return "Just now";
    } else if (elapsedMinutes < 60) {
      return `${elapsedMinutes} ${elapsedMinutes === 1 ? 'minute' : 'minutes'} ago`;
    } else if (elapsedHours < 24 && now.date() === messageTimeLocal.date()) {
      return `${elapsedHours} ${elapsedHours === 1 ? 'hour' : 'hours'} ago`;
    } else if (elapsedHours < 48 && now.date() - 1 === messageTimeLocal.date()) {
      return "Yesterday";
    } else if (elapsedHours < 24 * 7) {
      return `${Math.floor(elapsedHours / 24)} ${Math.floor(elapsedHours / 24) === 1 ? 'day' : 'days'} ago`;
    } else {
      return messageTimeLocal.format('MMM D, YYYY [at] h:mm A'); // Format to desired display format
    }
  };

  const getUserTimeZone = (countryCode) => {
    const timezones = countryTimezones.getCountryTimezones(countryCode);
    return timezones && timezones[0] ? timezones[0] : 'UTC';
  };

  // Sort messages by timestamp
  const sortedMessages = [...messages].sort((a, b) => new Date(a.timestamp) - new Date(b.timestamp));

  return (
    <div>
      <ul className="divide-y divide-gray-200">
        {sortedMessages.map(message => (
          <li key={message.id}>
            <div className={`max-w-md ${message.sender_id === loginUserId ? 'bg-orange-100' : 'bg-white'} rounded-lg p-2 mb-1`}>
              <div className="text-sm">{message.name}</div>
              <div className="whitespace-pre-wrap">{message.content}</div>
              <div className="text-xs -mt-5 text-right text-gray-500">{calculateHoursSinceMessage(message.timestamp)}</div>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default MessageList;
