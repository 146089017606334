import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone, faEnvelope, faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';



const AdamCallingCard = (data) => {
  return (
    <div style={styles.card}>
      <h2 style={styles.company}>{data.company}</h2>
      <p style={styles.name}>{data.name}</p>
      <p style={styles.title}>{data.title}</p>
      <p style={styles.contact}>
        <FontAwesomeIcon icon={faPhone} /> {data.phone}
      </p>
      <p style={styles.contact}>
        <FontAwesomeIcon icon={faEnvelope} />{' '}
        <span>{data.email}</span> {/* Change Link to span */}
      </p>
      <p style={styles.address}>
        <FontAwesomeIcon icon={faMapMarkerAlt} /> {data.address}
      </p>
    </div>
  );
};

const styles = {
  card: {
    border: '1px solid #ccc',
    borderRadius: '8px',
    padding: '16px',
    width: '300px', /* Adjust width for portrait */
    height: '450px', /* Adjust height for portrait */
    boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
    backgroundImage: "url('/images/adamsmith.png')",
    backgroundSize: 'cover', // Ensure the image covers the entire background
    backgroundPosition: 'center', // Center the image
    backgroundRepeat: 'no-repeat', // Prevent the image from repeating
  },
  company: {
    margin: '190px 0 15px',
    fontSize: '16px',
    color: '#000000',
    textAlign: 'center',
  },
  name: {
    margin: '0 0 15px',
    fontSize: '20px',
    color: '#000000',
    textAlign: 'center',
  },
  title: {
    fontSize: '10px',
    color: '#000000',
    textAlign: 'center',
  },
  contact: {
    margin: '0 0 15px',
    fontSize: '14px',
    textAlign: 'center',
    color: '#000000',
  },
  address: {
    fontSize: '14px',
    color: '#000000',
    textAlign: 'center',
  },
};

export default AdamCallingCard;
