import React, {useEffect, useState} from 'react';
import config from '../../config';
import Alert from "../atom/Alert";

const {backendUrl} = config;

const AccountForm = () => {
    const [alert, setAlert] = useState({show: false, type: '', message: ''});


    const [userData, setUserData] = useState({
        email: '',
        name: '',
        address: '',
        profile_url: '',
        invitation_code: '',
    });
    const handleChange = (e) => {
        const {name, value} = e.target;
        setUserData((prevData) => ({...prevData, [name]: value}));
    };
    const handleInputChange = (event) => {
        const {name, value} = event.target;
        setUserData(prevAccount => ({
            ...prevAccount,
            [name]: value
        }));
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const authToken = localStorage.getItem('authToken');
                if (!authToken) throw new Error('No auth token found');

                const response = await fetch(`${backendUrl}/api/account`,
                    {headers: {Authorization: `Bearer ${authToken}`},});
                if (!response.ok) throw new Error('Failed to fetch data');

                const data = await response.json();
                setUserData(data);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, []);

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const authToken = localStorage.getItem('authToken');
            if (!authToken) throw new Error('No auth token found');

            const response = await fetch(`${backendUrl}/api/account`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${authToken}`,
                },
                body: JSON.stringify(userData),
            });

            if (!response.ok) throw new Error('Failed to save data');

            setAlert({show: true, type: 'success', message: 'submitted successfully!'});

        } catch (error) {
            setAlert({show: true, type: 'error', message: 'Failed to save profile. Please try again later.'});
        }
    };

    return (
        <div className="relative p-8">
            <Alert
                type={alert.type}
                message={alert.message}
                show={alert.show}
                onClose={() => setAlert({...alert, show: false})}
            />
            <form onSubmit={handleSubmit}>
                <div className="border-b-2 pb-6">
                    <div>
                        {/* Conditionally render the alert div */}

                        <div className="text-2xl font-bold pb-6 ">Account</div>
                        <div className="text-gray-500">Share your unique Profile URL with people you'd like to
                            connect with.
                        </div>
                    </div>
                    <div className="pt-6 pb-6">
                        <label htmlFor="profileUrl"
                               className="block text-sm font-semibold font-medium text-gray-700">Profile
                            URL</label>
                        <input
                            type="text"
                            name="profile_url"
                            id="profile_url"
                            value={userData.profile_url}
                            onChange={handleInputChange}
                            className="mt-1 block w-full px-3 py-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                        />

                        <div className="text-gray-500 text-sm">Share this public URL with people you want to
                            connect with.
                        </div>
                    </div>
                    <div className="w-96">
                        <label htmlFor="invitationCode"
                               className="font-semibold block text-sm font-medium text-gray-700">Invitation
                            Code</label>
                        <input type="text" name="invitation_code" value={userData.invitation_code}
                               onChange={handleInputChange}
                               className="mt-1 block w-full px-3 py-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"/>

                        <div className="text-gray-500 text-sm">Share this with people you want to connect with
                            immediately without them needing approval from you
                        </div>
                    </div>
                </div>

                <div className="border-b-2 pt-6 pb-6 mb-6">
                    <div className="font-semibold "> Personal Information</div>
                    <div className="text-gray-500 text-sm mb-4">Communication details in case we want to connect
                        with you. These will be kept private.
                    </div>
                    <label htmlFor="email"
                           className="font-semibold block text-sm font-medium text-gray-700">Email</label>
                    <input
                        type="email"
                        id="email"
                        name="email"
                        value={userData.email}
                        onChange={handleChange}
                        className="mt-1 block w-full px-3 py-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    />
                </div>
                <div className="flex justify-end space-x-4">
                    <button
                        type="button"
                        className="px-4 py-2 bg-gray-300 text-gray-700 rounded-md hover:bg-gray-400 focus:outline-none focus:ring-2 focus:ring-gray-500"
                    >
                        Cancel
                    </button>
                    <button
                        type="submit"
                        className="px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500"
                    >
                        Save
                    </button>
                </div>
            </form>
        </div>
    );
};

export default AccountForm;
