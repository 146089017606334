import axios from 'axios';
import config from '../config';


const { backendUrl } = config;
      const auth = localStorage.getItem('authToken');
console.log("~~~~~~~~~~~~");
console.log(auth);


export const getData = async (endpoint) => {
    try {
      const response = await axios.get(`${backendUrl}/api${endpoint}`, {
        headers: {
          Authorization: `Bearer ${auth}`
        }
      });
      return response.data;
    } catch (error) {
      console.error(`Error fetching data from ${endpoint}:`, error);
      return null;
    }
};

export const postData = async (endpoint, payload) => {
    try {
        const response = await axios.post(`${backendUrl}/api/${endpoint}`, payload, {
            headers: {
                Authorization: `Bearer ${auth}`
            }
        });
        return response.data;
    } catch (error) {
        console.error(`Error posting data to ${endpoint}:`, error);
        return null;
    }
};

export const putData = async (endpoint, payload, auth) => {
    try {
        const response = await axios.put(`${backendUrl}/api/${endpoint}`, payload, {
            headers: {
                Authorization: `Bearer ${auth}`
            }
        });
        return response.data;
    } catch (error) {
        console.error(`Error putting data to ${endpoint}:`, error);
        return null;
    }
};
