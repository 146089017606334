import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBell } from '@fortawesome/free-solid-svg-icons';
import { Link } from "react-router-dom";
import config from '../../config';

const { backendUrl } = config;

const NotificationBell = () => {
  const [notifications, setNotifications] = useState([]);
  const [showDropdown, setShowDropdown] = useState(false);

  useEffect(() => {
    fetchNotifications();
  }, []);

  const fetchNotifications = async () => {
    try {
      const response = await axios.get(`${backendUrl}/api/notification`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('authToken')}`
        }
      });
      setNotifications(response.data);
    } catch (error) {
      console.error('Error fetching notifications:', error);
    }
  };

  const toggleDropdown = () => {
    setShowDropdown(!showDropdown);
  };

  const renderTimestamp = (timestamp) => {
    const date = new Date(timestamp);
    const options = { month: 'short', day: 'numeric', hour: 'numeric', minute: 'numeric' };
    return new Intl.DateTimeFormat('en-US', options).format(date);
  };

  const makeFirstWordBold = (message) => {
    const words = message.split(' ');
    return (
      <span>
        <span className="font-semibold">{words[0]}</span>{' '}
        {words.slice(1).join(' ')}
      </span>
    );
  };

  const countPendingNotifications = () => {
    return notifications.filter(notification => notification.status === 'pending').length;
  };

  return (
    <div className="relative">
      <div className="bell-icon relative bg-orange-500 w-7 h-7 rounded-full flex items-center justify-center">
        <button onClick={toggleDropdown}>
          <FontAwesomeIcon icon={faBell} className="text-white" />
        </button>
        {countPendingNotifications() > 0 && (
          <span className="notification-count bg-blue-600 text-white absolute -top-4 -right-4 mt-1 mr-1 rounded-full px-2 py-1 text-xs">
            {countPendingNotifications()}
          </span>
        )}
      </div>
      {showDropdown && (
        <div className="notification-dropdown absolute right-0 mt-3 w-48 bg-white shadow-lg rounded-lg py-1">
          {notifications.length > 0 ? (
            notifications.map(notification => (
              <Link to={notification.link} key={notification.id}>
                <div className="notification px-4 py-2">
                  {makeFirstWordBold(notification.message)}
                  <span className="text-gray-500 text-xs ml-1">{renderTimestamp(notification.timestamp)}</span>
                </div>
              </Link>
            ))
          ) : (
            <p className="px-4 py-2">No new notifications</p>
          )}
        </div>
      )}
    </div>
  );
};

export default NotificationBell;
