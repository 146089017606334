import React, { useState } from 'react';

const MessageInput = ({ onSendMessage }) => {
  const [content, setContent] = useState('');

  const handleMessageSubmit = async () => {
    if (content.trim() !== '') {
      await onSendMessage(content);
      setContent('');
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      handleMessageSubmit();
    } else if (e.key === 'Enter' && e.shiftKey) {
      e.preventDefault();
      setContent((prevContent) => prevContent + '\n');
    }
  };

  return (
    <div>
      <div className="flex items-center">
        <textarea
          value={content}
          onChange={(e) => setContent(e.target.value)}
          onKeyDown={handleKeyDown}
          className="flex-1 py-2 px-4 rounded-l-lg border-gray-300 focus:outline-none focus:ring focus:border-blue-300"
          rows="1"
        />
        <button
          onClick={handleMessageSubmit}
          className="bg-blue-500 hover:bg-blue-600 text-white py-2 px-4 rounded-r-lg"
        >
          Send
        </button>
      </div>
    </div>
  );
};

export default MessageInput;
