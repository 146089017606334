import React, {useState, useEffect} from 'react';
import {Link} from 'react-router-dom';
import config from '../config';

const {backendUrl} = config;
const ForgotPasswordPage = () => {
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [isEmailValid, setIsEmailValid] = useState(false);

    const validateEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    useEffect(() => {
        setIsEmailValid(validateEmail(email));
    }, [email]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await fetch(`${backendUrl}/api/account/forgot-password`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({email}),
            });
            const data = await response.json();
            setMessage(data.message);
        } catch (error) {
            setMessage(error.message || 'Something went wrong!');
        }
        console.log("print");
    };

    return (
        <div>
            <div
                className="flex flex-col sm:flex-row items-center md:items-start sm:justify-center md:justify-start flex-auto min-w-0 h-screen bg-white">
                <div
                    className="md:flex md:items-center md:justify-end w-full sm:w-auto md:h-full md:w-1/2 py-8 px-4 sm:p-12 md:p-16 sm:rounded-2xl md:rounded-none sm:shadow md:shadow-none sm:bg-card ng-tns-c775625097-1">
                    <div className="w-full max-w-80 sm:w-80 mx-auto sm:mx-0">
                        <div><Link to="/"><img src="/whooks.png" alt="Whooks Logo"/></Link></div>
                        <div className="mt-4 mb-4">
                            <div className="text-2xl font-bold text-gray-700">Forgot Password</div>
                            <div className="text-sm text-gray-500">Enter your email address below and we'll email you a
                                link to reset it
                            </div>
                        </div>
                        <form onSubmit={handleSubmit}>
                            <div className="mb-4">
                                <label htmlFor="email" className="block text-sm font-medium text-gray-700">Email
                                    Address*</label>
                                <input
                                    type="email"
                                    id="email"
                                    className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring focus:border-blue-300"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    required
                                />
                            </div>
                            <button type="submit"
                                    className={`w-full px-4 py-2 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 ${
                                        isEmailValid
                                            ? 'bg-blue-600 text-white hover:bg-blue-700'
                                            : 'bg-gray-400 text-gray-700 cursor-not-allowed'
                                    }`}
                                    disabled={!isEmailValid}>
                                Submit
                            </button>
                        </form>
                        {message && <p>{message}</p>}
                    </div>
                </div>
                <div
                    className="relative hidden md:flex flex-auto items-center justify-center h-full p-16 lg:px-28 overflow-hidden bg-gray-800 dark:border-l ng-tns-c2176845880-1">
                    <svg viewBox="0 0 960 540" width="100%" height="100%" preserveAspectRatio="xMidYMax slice"
                         xmlns="http://www.w3.org/2000/svg"
                         className="absolute inset-0 pointer-events-none ng-tns-c2176845880-1">
                        <g fill="none" stroke="currentColor" strokeWidth="100"
                           className="text-gray-700 opacity-25 ng-tns-c2176845880-1">
                            <circle r="234" cx="196" cy="23" className="ng-tns-c2176845880-1"></circle>
                            <circle r="234" cx="790" cy="491" className="ng-tns-c2176845880-1"></circle>
                        </g>
                    </svg>
                    <svg viewBox="0 0 220 192" width="220" height="192" fill="none"
                         className="absolute -top-16 -right-16 text-gray-700 ng-tns-c2176845880-1">
                        <defs className="ng-tns-c2176845880-1">
                            <pattern id="837c3e70-6c3a-44e6-8854-cc48c737b659" x="0" y="0" width="20" height="20"
                                     patternUnits="userSpaceOnUse" className="ng-tns-c2176845880-1">
                                <rect x="0" y="0" width="4" height="4" fill="currentColor"
                                      className="ng-tns-c2176845880-1"></rect>
                            </pattern>
                        </defs>
                        <rect width="220" height="192" fill="url(#837c3e70-6c3a-44e6-8854-cc48c737b659)"
                              className="ng-tns-c2176845880-1"></rect>
                    </svg>
                    <div className="z-10">
                        <div className="text-6xl font-bold leading-none text-gray-100 ng-tns-c2176845880-1">
                            <div>Welcome to</div>
                            <div>Whooks</div>
                        </div>
                        <div className="mt-4 text-gray-400">The app to get to know someone better by asking and
                            answering questions!
                        </div>
                        <div className="flex items-center mt-8 ng-tns-c2176845880-1">
                            <div className="flex flex-0 items-center -space-x-1.5 ng-tns-c2176845880-1">
                                <img src="/assets/images/avatars/female-01.png" alt="Avatar"
                                     className="flex-0 w-10 h-10 rounded-full ring-4 ring-offset-1 ring-gray-800 ring-offset-gray-800 object-cover ng-tns-c2176845880-1"/>
                                <img src="/assets/images/avatars/female-02.png" alt="Avatar"
                                     className="flex-0 w-10 h-10 rounded-full ring-4 ring-offset-1 ring-gray-800 ring-offset-gray-800 object-cover ng-tns-c2176845880-1"/>
                                <img src="/assets/images/avatars/male-01.png" alt="Avatar"
                                     className="flex-0 w-10 h-10 rounded-full ring-4 ring-offset-1 ring-gray-800 ring-offset-gray-800 object-cover ng-tns-c2176845880-1"/>
                                <img src="/assets/images/avatars/male-02.png" alt="Avatar"
                                     className="flex-0 w-10 h-10 rounded-full ring-4 ring-offset-1 ring-gray-800 ring-offset-gray-800 object-cover ng-tns-c2176845880-1"/>
                            </div>
                            <div className="ml-4 font-medium tracking-tight text-gray-400 ng-tns-c2176845880-1">More
                                than 17k people joined us, it's your turn
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ForgotPasswordPage;
