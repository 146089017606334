import React, { useState, useEffect } from 'react';
import { getData } from '../../api/crud';
import { useParams } from "react-router-dom";

const RoomList = () => {
    const [rooms, setRooms] = useState([]);
    const loginUserId = parseInt(localStorage.getItem('userId'));
    const { roomId } = useParams();

    useEffect(() => {
        getData('/rooms').then(data => {
            if (data) {
                setRooms(data);
                console.log('Rooms fetched:', data);
            } else {
                // Handle error or show a message to the user
                console.error('Failed to fetch rooms.');
            }
        });
    }, []);

    useEffect(() => {
        if (window.location.pathname === '/message' && rooms.length > 0) {
            const firstRoomId = rooms[0]?.id;
            window.location.href = `/message/${firstRoomId}`;
        }
    }, [rooms]);

    const renderRoomName = (room) => {
        if (room.user_permissions.length === 2) {
            const otherUser = room.user_permissions.find(permission => permission.user_id !== loginUserId);
            if (otherUser) {
                return otherUser.name;
            }
        }
        return room.name;
    };

    return (
        <div>
            <ul>
                {rooms.map(room => (
                    <li key={room.id} className={roomId === room.id.toString() ? 'bg-orange-100 mb-2' : 'mb-2'}>
                        <a href={`/message/${room.id}`} className="w-full p-2 border-b-2 cursor-pointer hover:bg-gray-100 transition duration-300 ease-in-out block">
                            <div className="font-semibold">{renderRoomName(room)}</div>
                            <div className="text-gray-600 text-sm">Software Developer</div>
                        </a>
                    </li>
                ))}
            </ul>
        </div>
    );
};
export default RoomList;
